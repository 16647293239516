import React from "react";
import PropTypes from "prop-types";
import {graphql, Link, useStaticQuery} from "gatsby";

import Header from "./header";
import artisanLogo from "../images/logo-artisan-made.png";
import Main from "./atoms/main";
import ButtonLink from "./atoms/button-link";
import {link_eclub} from "../helpers/constants";

function Layout({children, useMain = true, hideMenu = false, customFooterMessage = null}) {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

    let main = null;

    if (useMain === true) {
        main = (
            <Main>
                {children}
            </Main>
        )
    } else {
        main = (
            <>
                {children}
            </>
        )
    }

    let footerComponent = null;

    if (customFooterMessage) {
        footerComponent = (
            <footer className="bg-white">
                <div className=" justify-between max-w-6xl mx-auto p-4 md:p-8 text-sm">

                    <div className="flex flex-wrap">
                        <div className="w-full mb-3 ">
                            {customFooterMessage}
                        </div>
                    </div>
                </div>
            </footer>
        );
    } else {
        footerComponent = (
            <footer className="bg-white">
                <nav className=" justify-between max-w-6xl mx-auto p-4 md:p-8 text-sm">

                    <div className="flex flex-wrap">
                        <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-4 pr-10 pb-10">
                            <Link to="/" className="flex items-center no-underline text-black ">
                                <img
                                    src={artisanLogo}
                                    className="fill-current mr-2 w-50"
                                    alt={'Artisan Made Pizza Logo'}
                                />
                            </Link>
                        </div>
                        <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-4">
                            <h3 className={'text-red-800 text-2xl font-bold uppercase'}>Franchising</h3>
                            <ul>
                                <li className={'pt-2'}><Link to={'/'}>Our Brand</Link></li>
                                <li className={'pt-2'}><Link to={'/'}>Our Leadership</Link></li>
                                <li className={'pt-2'}><Link to={'/'}>Franchise Process</Link></li>
                                <li className={'pt-2'}><Link to={'/'}>Investment</Link></li>
                                <li className={'pt-2'}><Link to={'/'}>Franchisee Support</Link></li>
                                <li className={'pt-2'}><Link to={'/'}>Available Locations</Link></li>
                                <li className={'pt-2'}><Link to={'/'}>Franchisee Application</Link></li>
                            </ul>
                        </div>
                        <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-4">
                            <h3 className={'text-red-800 text-2xl font-bold uppercase'}>About Us</h3>
                            <ul>
                                <li className={'pt-2'}><Link to={'/'}>Our History</Link></li>
                                <li className={'pt-2'}><Link to={'/'}>Careers</Link></li>
                                <li className={'pt-2'}><Link to={'/'}>Nutrition</Link></li>
                                <li className={'pt-2'}><Link to={'/'}>FAQ</Link></li>
                                <li className={'pt-2'}><Link to={'/'}>Press</Link></li>
                                <li className={'pt-2'}><Link to={'/'}>Gift Card Balance</Link></li>
                                <li className={'pt-2'}><Link to={'/'}>Contact</Link></li>
                            </ul>
                        </div>
                        <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-4">
                            <h3 className={'text-red-800 text-2xl font-bold uppercase'}>Never miss a deal</h3>
                            <p>Receive special discounts and exclusive offers you won't find anywhere else!</p>
                            <ButtonLink link={link_eclub}>Join EClub!</ButtonLink>

                            <h3 className={'text-red-800 text-2xl font-bold uppercase mt-8'}>Connect with us!</h3>
                        </div>
                    </div>
                    <div>
                        <p className={'text-gray-700'}>Copyright © 2019 Pizza Guys Franchises, Inc. All Rights
                            Reserved.</p>
                    </div>
                </nav>
            </footer>
        )
    }

    return (
        <div className="flex flex-col font-sans min-h-screen text-gray-900">
            <Header hideMenu={hideMenu} siteTitle={data.site.siteMetadata.title}/>

            {main}

            {footerComponent}

        </div>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
};

export default Layout;
