import {Link} from "gatsby";
import PropTypes from "prop-types";
import React, {useState} from "react";
import logo from "../images/logo.png";
import {link_eclub, link_locations, link_menu, link_specials} from "../helpers/constants";

function Header({siteTitle, hideMenu = false}) {
    const [isExpanded, toggleExpansion] = useState(false);

    let menuComponent = null;

    if (hideMenu === false) {
        menuComponent = (
            <>
                <button
                    className="block md:hidden border border-white flex items-center px-3 py-2 rounded text-black"
                    onClick={() => toggleExpansion(!isExpanded)}
                >
                    <svg
                        className="fill-current h-3 w-3"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <title>Menu</title>
                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                    </svg>
                </button>

                <div
                    className={`${
                        isExpanded ? `block` : `hidden`
                    } md:block md:flex md:items-center w-full md:w-auto`}
                >
                    <div className="text-md">
                        <Link
                            to={link_menu}
                            className="block md:inline-block mt-4 md:mt-0 mr-6 no-underline uppercase text-black text-right hover:text-red-800 font-bold"
                        >
                            Menu
                        </Link>

                        <Link
                            to={link_locations}
                            className="block md:inline-block mt-4 md:mt-0 mr-6 no-underline uppercase text-black text-right hover:text-red-800 font-bold"
                        >
                            Locations
                        </Link>

                        <Link
                            to={link_specials}
                            className="block md:inline-block mt-4 md:mt-0 mr-6 no-underline uppercase text-black text-right hover:text-red-800 font-bold"
                        >
                            Specials
                        </Link>

                        <Link
                            to={link_eclub}
                            className="block md:inline-block mt-4 md:mt-0 mr-6 no-underline uppercase text-black text-right hover:text-red-800 font-bold"
                        >
                            E-Club
                        </Link>
                    </div>
                </div>
            </>
        );
    }

    return (
        <nav className="bg-while shadow">
            <div
                className={` ${hideMenu === true ? "justify-center" : "justify-between"} flex flex-wrap items-center  max-w-6xl mx-auto p-4 md:p-8`}>
                <a href="https://www.pizzaguys.com" className="flex items-center no-underline text-black ">
                    <img
                        src={logo}
                        className="fill-current h-8 mr-2 w-50 "
                        alt={siteTitle}
                    />
                </a>


                {menuComponent}
            </div>
        </nav>
    );
}

Header.propTypes = {
    siteTitle: PropTypes.string
};

Header.defaultProps = {
    siteTitle: ``
};

export default Header;
