import React from "react";
import PropTypes from "prop-types";
import {Link} from "gatsby";

function ButtonLink({children, link}) {


    return (
        <Link className={'bg-red-700 mt-4 p-3 pr-6 pl-6 rounded text-white font-bold text-sm uppercase inline-block'}
              to={link}>{children}</Link>
    );
}

ButtonLink.propTypes = {
    children: PropTypes.node.isRequired
};

export default ButtonLink;
